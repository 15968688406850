
import { defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";
import { useRouter } from "vue-router";

import CFormInputText from "@/components/form/InputText.vue";
import { formatCPF, removeCPFMask } from "@/libs/utils";

const VLogin = defineComponent({
  name: "VLogin",
  components: { CFormInputText },
  setup() {
    useHead({ title: "Faça login com sua conta logo abaixo| obmed" });

    const store = useStore();
    const router = useRouter();

    const form = reactive({ username: "", password: "" });
    const loading = reactive({ submit: false });
    const show = reactive({ password: false });

    async function handleLogin() {
      loading.submit = true;
      const response = await store.dispatch("createSession", { form });
      loading.submit = false;

      if (response?.status === 200) router.replace({ name: "root" });
    }

    return { form, loading, show, handleLogin, formatCPF, removeCPFMask };
  },
});

export default VLogin;
